import React, { useState } from "react";
import { Link, graphql } from "gatsby";
import { Container, Row, Col, Card, Button, Carousel } from "react-bootstrap";
import Img from "gatsby-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import SEO from "../components/seo";
import ReactMarkdown from "react-markdown/with-html";
import Layout from "../components/Layout";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

export const AdasPageTemplate = ({
  title,
  titleSEO,
  headerImage,
  buttonLeft,
  buttonRight,
  companyLogo,
  content,
  adasProImage,
  adasMobileImage,
  adasMobileTargetsImage,
  adasThrottleImageFront,
  adasThrottleImageRear,
}) => {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <>
      <SEO title={titleSEO} />
      <Container className="d-flex flex-column justify-content-center align-items-center">
        <Row className="text-center mt-5 pt-3">
          <Col>
            <h1 className="block-invert">{title}</h1>
          </Col>
        </Row>
        <Row className="my-5 pt-0 pt-sm-5">
          <Col md={8}>
            <p className="pr-0 pr-lg-5 text-justify corner-block">
              <ReactMarkdown source={content} escapeHtml={false} linkTarget="_blank" />
            </p>
          </Col>
          <Col md={4}>
            <PreviewCompatibleImage
              imageInfo={{
                image: headerImage,
                alt: "image",
                style: { border: "solid rgb(117, 117, 117) 2px" },
              }}
            />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="my-5 d-flex flex-column justify-content-center">
          <Col className="d-flex flex-row justify-content-center">
            <p className="text-center block-invert pt-3">
              <h3 className="text-center">Calibration Packages</h3>
            </p>
          </Col>
        </Row>
        <Row className="my-5 py-5 d-flex flex-row justify-content-center">
          <Col xs={12} sm={4} className="text-center">
            <AnchorLink to="/adas/#x431-adas-pro">
              <Button variant="outline-danger" className="px-4 px-md-3 py-3 pt-4 mb-3 mb-md-0">
                <h5>X-431 ADAS PRO</h5>
              </Button>
            </AnchorLink>
          </Col>
          <Col xs={12} sm={4} className="text-center">
            <AnchorLink to="/adas/#x431-adas-mobile">
              <Button variant="outline-danger" className="p-md-3 py-3 px-2 pt-4 mb-3 mb-md-0">
                <h5>X-431 ADAS MOBILE</h5>
              </Button>
            </AnchorLink>
          </Col>
          <Col xs={12} sm={4} className="text-center">
            <AnchorLink to="/adas/#x431-throttle">
              <Button variant="outline-danger" className="px-4 px-md-3 py-3 pt-4 mb-3 mb-lg-0">
                <h5>X-431 THROTTLE</h5>
              </Button>
            </AnchorLink>
          </Col>
        </Row>
      </Container>
      <Container>
        <span id="x431-adas-pro">
          <h1 style={{ paddingTop: "80px", marginTop: "-80px" }}></h1>
        </span>
        <Row className="my-5">
          <Col xs={12} md={6}>
            <h2 className="d-block d-md-none border-bottom-red text-center text-lg-left">
              <span>X-431 ADAS PRO</span>
            </h2>
            <PreviewCompatibleImage imageInfo={{ image: adasProImage, alt: "image", style: { width: "100%" } }} />
            <h4 className="strong">Description</h4>
            <hr />
            <p className="font-normal">
              ADAS stands for Advanced Driver Assist Systems- These systems are designed to create a safer environment
              for the driver and those around them. These systems help reduce the possibility of an accident or
              collision. They have been around since the late 1990’s but used in the early to mid 2000’s in the US with
              the use of Adaptive Cruise Control or ACC. These vehicle’s made use of LiDAR distance detection but only
              offered a warning to the driver.
            </p>
            <p className="font-normal">
              Those systems would be classified as a passive system. Today's systems are more advanced and integrated.
              They use multiple sensor inputs such as cameras and radar to assist and warn the driver of surrounding
              danger. The increase in complexity has brought about a challenge in the auto industry. You can no longer
              perform basic procedures or replace components and not worry about it affecting these driver assist
              systems. And if you happen to unplug or replace a component that is attached to these systems, you will
              find that it will require a recalibration to clear a warning or check engine light. You can’t just clear
              the code.
            </p>
            <p className="font-normal">
              Here is a list of the most common procedures that require a recalibration:
              <ul>
                <li>Windshield Replacement</li>
                <li>Alignment</li>
                <li>Front and rear bumper replacement</li>
                <li>Side view mirrors</li>
                <li>Rear trunk or tailgate</li>
                <li>Front or rear collision</li>
              </ul>
            </p>
          </Col>
          <Col xs={12} md={6}>
            <h2 className="d-none d-md-block border-bottom-red text-center text-lg-left">
              <span>X-431 ADAS PRO</span>
            </h2>
            <h4 className="strong">Features</h4>
            <ul>
              <li>
                The modular structure and useful accessories enable you to configure the tool in the best way to suit
                your workshop.
              </li>
              <li>Easily and precisely adjust calibration frame to ensure vehicle is correctly centered.</li>
              <li>Can be used even without an axial measuring system.</li>
              <li>Multiple calibration function, wide car model coverage.</li>
              <li>All kinds of vehicle-specific calibration panels are available.</li>
              <li>Provide the instruction before beginning a calibration procedure.</li>
            </ul>
            <hr />
            <h4 className="strong pt-2">Types of Calibration</h4>
            <p className="font-normal pl-3">
              Dynamic vs static calibration- There are two types of recalibration that are performed for ADAS.
            </p>
            <p className="font-normal pl-3">
              <i>Static calibration</i> – is a calibration that is performed in a controlled environment (indoor shop
              with good lighting, level ground, and adequate space) with special targets placed in specific locations in
              front and around the vehicle. A scanner is also required to communicate with the modules of that vehicle.
            </p>
            <p className="font-normal pl-3">
              <i>Dynamic calibration</i> – also referred to as mobile calibration, is performed by driving your car for
              a prescribed distance, at a prescribed speed and in optimum weather conditions. A handheld device
              (scanner) that aids the process is attached to the car during the test. The purpose of this type of
              calibration is for the systems to get accustomed to the road so that they can be ready for all the
              functions they’re expected to perform.{" "}
            </p>
            <hr />
            <h4 className="strong pt-2">Vehicle Coverage</h4>
            <p className="font-normal pl-3">
              <i>American</i> <br />
              GM, Ford, Chrysler
            </p>
            <p className="font-normal pl-3">
              <i>European</i> <br />
              Mercedes-Benz, BMW, Audi, Volkswagen, MINI, Jaguar, Volvo, Fiat, SEAT, Skoda, SMART, Renault, Land Rover,
              Opel, Citroen
            </p>
            <p className="font-normal pl-3">
              <i>Asian</i> <br />
              Toyota, Lexus, Honda, Acura, Kia, Hyundai, Nissan, Infiniti, Subaru, Suzuki, Mitsubishi, Daihatsu
            </p>
            <hr />
            <h4 className="strong pt-2">Specifications</h4>
            <p className="font-normal pl-3">
              <i>Works with:</i>
            </p>

            <ul>
              <li>X-431 Throttle</li>
              <li>X-431 Pad II AE</li>
              <li>X-431 Torque</li>
            </ul>

            <hr />
            <h4 className="strong pt-2">ADAS Package Pricing</h4>
            <p className="font-normal pl-3">
              Premium Plus - <strong>$24,999</strong>
            </p>
          </Col>
        </Row>
        <hr />
        <span id="x431-adas-mobile">
          <h1 style={{ paddingTop: "80px", marginTop: "-80px" }}></h1>
        </span>
        <Row className="my-5 pt-5">
          <Col xs={12} md={6}>
            <h2 className="d-block d-md-none border-bottom-red text-center text-lg-left">
              <span>X-431 ADAS MOBILE</span>
            </h2>
            <Carousel activeIndex={index} onSelect={handleSelect} style={{ height: "100vh" }}>
              <Carousel.Item>
                <PreviewCompatibleImage
                  imageInfo={{ image: adasMobileImage, alt: "image", style: { width: "100%" } }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <PreviewCompatibleImage
                  imageInfo={{ image: adasMobileTargetsImage, alt: "image", style: { width: "100%" } }}
                />
              </Carousel.Item>
            </Carousel>
          </Col>
          <Col xs={12} md={6}>
            <h2 className="d-none d-md-block border-bottom-red text-center text-lg-left">
              <span>X-431 ADAS MOBILE</span>
            </h2>
            <h4 className="strong">Description</h4>
            <p className="font-normal pl-3">
              X-431 ADAS Mobile is a portable ADAS calibration tool for calibrating ADAS system and ideal for repair
              business to target new revenue stream. The unique fully foldable design can minimize the transporting
              risk, speed up the setup procedure and calibrate in various place. Features with 23-style manufacturer
              standard targets and advanced ADAS software, X-431 ADAS Mobile offer the one-stop calibration service.
            </p>
            <hr />
            <h4 className="strong">Features</h4>
            <ul>
              <li>Start to calibrate in seconds, no more tedious work of disassembly and installation</li>
              <li>Lightweight and Mobile – Compact for easy storage</li>
              <li>Five-line & cross laser instrument ensure precision vehicle positioning.</li>
              <li>
                Target Kit has an extensive vehicle coverage application
                <Button variant="link" onClick={() => setIndex(1)}>
                  View Targets
                </Button>
              </li>
              <li>Concise User illustrations for simplified operation</li>
            </ul>

            <hr />
            <h4 className="strong pt-2">Vehicle Coverage</h4>
            <p className="font-normal pl-3">
              <i>American</i> <br />
              GM, Ford, Chrysler, Buick, Cadillac, Chevrolet, Dodge, Jeep, Lincoln, and more.
            </p>
            <p className="font-normal pl-3">
              <i>European</i> <br />
              Mercedes-Benz, BMW, Audi, Volkswagen, Land Rover, Jaguar, Volvo, Fiat, Opel, Seat, Skoda, Renault,
              Citroen, Smart, Mini, Peugeot, Porsche, and more.
            </p>
            <p className="font-normal pl-3">
              <i>Asian</i> <br />
              Kia, Hyundai, Toyota, Lexus, Honda, Acura, Nissan, lnfiniti, Mitsubishi, Subaru, Suzuki, Daihatsu, Mazda,
              and more.
            </p>
            <hr />
            <h4 className="strong pt-2">
              ADAS Package Pricing - <strong>$9,995</strong>
            </h4>
          </Col>
        </Row>
        <hr />
        <span id="x431-throttle">
          <h1 style={{ paddingTop: "80px", marginTop: "-80px" }}></h1>
        </span>
        <Row className="my-5 pt-5">
          <Col xs={12} md={6}>
            <h2 className="d-block d-md-none border-bottom-red text-center text-lg-left">
              <span>X-431 THROTTLE</span>
            </h2>
            <Carousel activeIndex={index} onSelect={handleSelect} style={{ height: "65vh" }}>
              <Carousel.Item>
                <PreviewCompatibleImage
                  imageInfo={{ image: adasThrottleImageFront, alt: "image", style: { width: "100%" } }}
                />
              </Carousel.Item>
              <Carousel.Item>
                <PreviewCompatibleImage
                  imageInfo={{ image: adasThrottleImageRear, alt: "image", style: { width: "100%" } }}
                />
              </Carousel.Item>
            </Carousel>
          </Col>
          <Col xs={12} md={6}>
            <h2 className="d-none d-md-block border-bottom-red text-center text-lg-left">
              <span>X-431 THROTTLE</span>
            </h2>
            <h4 className="strong">Description</h4>
            <p className="font-normal pl-3">
              X-431 Throttle is a high-end comprehensive diagnostic tool. It has a wide coverage of models, strong
              diagnostic capabilities, accurate detection data and other characteristics. The tablet has a protection
              level of IP65.
            </p>
            <hr />
            <h4 className="strong">Features</h4>
            <ul>
              <li>All new easy-to-use interface</li>
              <li>“Military tough" internal alloy chassis</li>
              <li>New faster Processor with Android 7.1</li>
              <li>New higher resolution and brighter display</li>
              <li>New Dual Band WIFI (2.4GHZ & 5.0GHZ)</li>
              <li>New 2 hour Quick Charge Technology</li>
              <li>Dual-Mode Bluetooth Technology</li>
              <li>13 MP Rear Camera</li>
              <li>Includes Charging Station</li>
              <li>OBD1 Kit Included</li>
            </ul>

            <hr />
            <h4 className="strong pt-2">
              ADAS Package Pricing - <strong>$3,999</strong>
            </h4>
          </Col>
          <Col className="d-flex flex-row justify-content-center mb-5">
            <p className="text-center">
              ADAS Calibration Equipment by:
              <PreviewCompatibleImage
                imageInfo={{
                  image: companyLogo,
                  alt: "image",
                  style: { maxWidth: "200px", marginLeft: "auto", marginRight: "auto", paddingTop: "12px" },
                }}
              />
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
};

const AdasPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  return (
    <Layout>
      <AdasPageTemplate
        title={frontmatter.title}
        titleSEO={frontmatter.titleSEO}
        headerImage={frontmatter.headerImage}
        content={frontmatter.content}
        buttonLeft={frontmatter.buttonLeft}
        buttonRight={frontmatter.buttonRight}
        companyLogo={frontmatter.companyLogo}
        adasProImage={frontmatter.adasProImage}
        adasMobileImage={frontmatter.adasMobileImage}
        adasMobileTargetsImage={frontmatter.adasMobileTargetsImage}
        adasThrottleImageFront={frontmatter.adasThrottleImageFront}
        adasThrottleImageRear={frontmatter.adasThrottleImageRear}
      />
    </Layout>
  );
};

export default AdasPage;

export const pageQuery = graphql`
  query AdasPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "adas-page" } }) {
      frontmatter {
        title
        titleSEO
        content
        headerImage {
          childImageSharp {
            fluid(maxWidth: 1000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        buttonLeft {
          childImageSharp {
            fluid(maxWidth: 300, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        buttonRight {
          childImageSharp {
            fluid(maxWidth: 300, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        companyLogo {
          childImageSharp {
            fluid(maxWidth: 200, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        adasProImage {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        adasMobileImage {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        adasMobileTargetsImage {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        adasThrottleImageFront {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        adasThrottleImageRear {
          childImageSharp {
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
